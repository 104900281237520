import GraphQLErrorList from "../components/graphql-error-list";
import PageHelmet from "../components/PageHelmet";
import Layout from "../containers/Layout";
import QHomePage from "../queries/QHomePage";
import { SanityHomePage, SanitySiteSettings } from "../../graphql-types";
import HomePage from "../components/HomePage";

interface IndexPageProps {
  site: SanitySiteSettings;
  homePage: SanityHomePage;
  posts: any;
}

const IndexPage = ({ errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const renderIndexPage = ({ site, homePage, posts }: IndexPageProps) => {
    const { donationUrl, ticketsUrl, streamUrl } = site;
    return (
      <Layout>
        <PageHelmet title={site.title} description={site.description} />
        <HomePage
          {...homePage}
          globalUrls={{ donationUrl, ticketsUrl, streamUrl }}
          tiltifyConfig={{
            tiltifyEventId: site.tiltifyEventId,
            tiltifyClientId: site.tiltifyClientId,
            tiltifyClientSecret: site.tiltifyClientSecret,
            tiltifyAmountRaisedOverride: site.tiltifyAmountRaisedOverride,
            tiltifyExtraAmountRaised: site.tiltifyExtraAmountRaised,
            tiltifyTotalDonationTargetOverride:
              site.tiltifyTotalDonationTargetOverride,
          }}
          streamathonLabel={site.streamathonLabel}
          streamathonDate={site.streamathonDate}
          lanLabel={site.lanLabel}
          lanDate={site.lanDate}
          posts={posts}
        />
      </Layout>
    );
  };

  return <QHomePage>{renderIndexPage}</QHomePage>;
};

export default IndexPage;
