import { FC } from "react";
import * as styles from "./AboutSection.module.scss";

import { SanityCta, SanityHomePage } from "../../graphql-types";
import PortableText from "./portableText";
import CtaButton from "./CtaButton";
import { cn } from "../lib/helpers";
import Container from "./Container";
import Image from "./Image";
import {
  bodyTextStyle,
  pageTitleStyle,
  blueText,
  orangeText,
} from "./typography.module.scss";

interface AboutSectionProps
  extends Pick<
    SanityHomePage,
    | "aboutTitle"
    | "aboutCopy"
    | "sponsorsTitle"
    | "desktopLogoGrid"
    | "sponsorsHidden"
    | "mobileLogoGrid"
    | "streamathonLabel"
    | "streamathonDate"
    | "lanLabel"
    | "lanDate"
    | "gfgLogoHidden"
    | "gfgLogo"
    | "datesHidden"
  > {
  joinCta: SanityCta;
}

const AboutSection: FC<AboutSectionProps> = ({
  aboutTitle,
  aboutCopy,
  joinCta,
  sponsorsTitle,
  desktopLogoGrid,
  mobileLogoGrid,
  sponsorsHidden,
  streamathonLabel,
  streamathonDate,
  lanLabel,
  lanDate,
  gfgLogoHidden,
  gfgLogo,
  datesHidden,
}) => {
  return (
    <div className={styles.root}>
      <img
        className={cn(styles.cablesImage)}
        src="../../homepage-cables.png"
        alt="background-cables"
      />
      <Container className={styles.container}>
        <div>
          <h2 className={cn(pageTitleStyle, styles.title)}>{aboutTitle}</h2>
          <div className={styles.copy}>
            <div className={bodyTextStyle}>
              <PortableText blocks={aboutCopy} />
            </div>
            <CtaButton
              label={joinCta.label}
              url={joinCta.url}
              theme="green"
              size="large"
              openInNewTabDisabled
            />
          </div>
          {!gfgLogoHidden && gfgLogo && (
            <Image
              image={{ alt: "Gamers for Giving", ...gfgLogo }}
              className={cn(styles.image)}
              hasRoundedCorners={false}
              fullQuality
            />
          )}
          {!datesHidden && (
            <div className={cn(styles.dates, pageTitleStyle)}>
              <div className={styles.date}>
                <span className={blueText}>{streamathonLabel}:</span>
                <span>{streamathonDate}</span>
              </div>
              <div className={styles.date}>
                <span className={orangeText}>{lanLabel}:</span>{" "}
                <span>{lanDate}</span>
              </div>
            </div>
          )}
          {!sponsorsHidden && (
            <div className={styles.sponsors}>
              <div className={cn(bodyTextStyle, styles.sponsorsTitle)}>
                {sponsorsTitle}
              </div>
              {desktopLogoGrid && (
                <Image
                  image={{ alt: "Our Sponsors", ...desktopLogoGrid }}
                  className={cn(styles.image, styles.desktopImage)}
                  hasRoundedCorners={false}
                  fullQuality
                />
              )}
              {mobileLogoGrid && (
                <Image
                  image={{ alt: "Our Sponsors", ...mobileLogoGrid }}
                  className={cn(styles.image, styles.mobileImage)}
                  hasRoundedCorners={false}
                  fullQuality
                />
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AboutSection;
