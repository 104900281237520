import { FC } from "react";
import { SanityCta, SanityFile } from "../../graphql-types";
import { cn } from "../lib/helpers";
import { desktopOnly, mobileOnly } from "../styles/utils.module.scss";
import CtaButton from "./CtaButton";
import * as styles from "./Hero.module.scss";
import { pageTitleStyle } from "./typography.module.scss";

export interface HeroProps {
  videoDesktop: SanityFile;
  videoMobile: SanityFile;
  ticketsCta: SanityCta;
  donateCta: SanityCta;
  streamCta: SanityCta;
  streamathonLabel: string;
  streamathonDate: string;
  lanLabel: string;
  lanDate: string;
}

const Hero: FC<HeroProps> = ({
  videoDesktop,
  videoMobile,
  ticketsCta,
  donateCta,
  streamCta,
  streamathonLabel,
  streamathonDate,
  lanLabel,
  lanDate,
}) => {
  return (
    <div className={styles.root}>
      {videoDesktop && (
        <div className={cn(videoMobile && desktopOnly)}>
          <video className={cn(styles.video)} autoPlay muted loop>
            <source src={videoDesktop.asset.url} type="video/mp4" />
          </video>
        </div>
      )}
      {videoMobile && (
        <div className={cn(mobileOnly)}>
          <video className={cn(styles.video)} autoPlay muted loop>
            <source src={videoMobile.asset.url} type="video/mp4" />
          </video>
        </div>
      )}
      <div className={styles.info}>
        <div className={cn(styles.dates)}>
          <div>
            <div className={cn(styles.dateLabel)}>{streamathonLabel}:</div>
            <div className={cn(styles.dateText, pageTitleStyle)}>
              {streamathonDate}
            </div>
          </div>
          <div>
            <div className={cn(styles.dateLabel)}>{lanLabel}:</div>
            <div className={cn(styles.dateText, pageTitleStyle)}>{lanDate}</div>
          </div>
        </div>
        <div className={styles.buttons}>
          {ticketsCta && (
            <CtaButton
              label={ticketsCta.label}
              url={ticketsCta.url}
              theme="green"
              openInNewTabDisabled
            />
          )}
          {streamCta && (
            <CtaButton
              label={streamCta.label}
              url={streamCta.url}
              theme="blue"
            />
          )}
          {donateCta && (
            <CtaButton
              label={donateCta.label}
              url={donateCta.url}
              theme="orange"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
