// extracted by mini-css-extract-plugin
export var cablesImage = "AboutSection-module--cablesImage--fBfl+";
export var container = "AboutSection-module--container--9NXGU";
export var copy = "AboutSection-module--copy--G70wf";
export var date = "AboutSection-module--date--nTXqf";
export var dates = "AboutSection-module--dates--G5xLH";
export var desktopImage = "AboutSection-module--desktopImage--q4QRO";
export var image = "AboutSection-module--image--EPZS-";
export var mobileImage = "AboutSection-module--mobileImage--QBEZs";
export var root = "AboutSection-module--root--Mim9Y";
export var sponsors = "AboutSection-module--sponsors--MIh-e";
export var sponsorsTitle = "AboutSection-module--sponsorsTitle--ZPPdt";
export var title = "AboutSection-module--title--JSHjK";