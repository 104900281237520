import { FC } from "react";
import { SanityHomePage, SanityPost } from "../../graphql-types";
import { GlobalUrls } from "../types/globalUrls";
import AboutSection from "./AboutSection";
import BlogHighlights from "./BlogHighlights";
import CampaignTracker, { TiltifyConfig } from "./CampaignTracker";
import FundraisingSection from "./FundraisingSection";
import Hero from "./Hero";

export interface HomePageProps extends SanityHomePage {
  tiltifyConfig: TiltifyConfig;
  globalUrls: GlobalUrls;
  streamathonLabel: string;
  streamathonDate: string;
  lanLabel: string;
  lanDate: string;
  posts: SanityPost[];
}

const HomePage: FC<HomePageProps> = ({
  datesHidden,
  heroVideoDesktop,
  heroVideoMobile,
  donateCtaLabel,
  ticketsCtaLabel,
  streamCtaLabel,
  aboutTitle,
  aboutCopy,
  _rawAboutCopy,
  joinCtaLabel,
  streamathonLabel,
  streamathonDate,
  lanLabel,
  lanDate,
  sponsorsTitle,
  sponsorsHidden,
  desktopLogoGrid,
  mobileLogoGrid,
  fundraisingTitle,
  fundraisingCopy,
  _rawFundraisingCopy,
  fundraisingImage,
  fundraisingCta,
  blogTitle,
  blogArchiveCtaLabel,
  tiltifyConfig,
  globalUrls,
  gfgLogo,
  gfgLogoHidden,
  posts,
}) => {
  return (
    <>
      <Hero
        streamathonLabel={streamathonLabel}
        streamathonDate={streamathonDate}
        lanLabel={lanLabel}
        lanDate={lanDate}
        videoDesktop={heroVideoDesktop}
        videoMobile={heroVideoMobile}
        donateCta={{ label: donateCtaLabel, url: globalUrls.donationUrl }}
        streamCta={{ label: streamCtaLabel, url: globalUrls.streamUrl }}
        ticketsCta={{ label: ticketsCtaLabel, url: "/info#tickets" }}
      />
      <CampaignTracker tiltifyConfig={tiltifyConfig} />
      <AboutSection
        aboutTitle={aboutTitle}
        aboutCopy={_rawAboutCopy || aboutCopy}
        joinCta={{ label: joinCtaLabel, url: "/info" }}
        sponsorsTitle={sponsorsTitle}
        desktopLogoGrid={desktopLogoGrid}
        mobileLogoGrid={mobileLogoGrid}
        sponsorsHidden={sponsorsHidden}
        streamathonLabel={streamathonLabel}
        streamathonDate={streamathonDate}
        lanLabel={lanLabel}
        lanDate={lanDate}
        gfgLogo={gfgLogo}
        gfgLogoHidden={gfgLogoHidden}
        datesHidden={datesHidden}
      />
      <FundraisingSection
        fundraisingTitle={fundraisingTitle}
        fundraisingCopy={_rawFundraisingCopy || fundraisingCopy}
        fundraisingImage={fundraisingImage}
        fundraisingCta={fundraisingCta}
      />
      <BlogHighlights
        blogTitle={blogTitle}
        posts={posts}
        blogArchiveCtaLabel={blogArchiveCtaLabel}
      />
    </>
  );
};

export default HomePage;
