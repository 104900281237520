import { FC } from "react";
import { SanityHomePage } from "../../graphql-types";
import Container from "./Container";
import * as styles from "./FundraisingSection.module.scss";
import * as utilStyles from "../styles/utils.module.scss";
import PortableText from "./portableText";
import CtaButton from "./CtaButton";
import Image from "./Image";

type FundraisingSectionProps = Pick<
  SanityHomePage,
  "fundraisingTitle" | "fundraisingCopy" | "fundraisingImage" | "fundraisingCta"
>;

const FundraisingSection: FC<FundraisingSectionProps> = ({
  fundraisingTitle,
  fundraisingCopy,
  fundraisingImage,
  fundraisingCta,
}) => {
  const TextWithCta = (
    <div className={styles.copyWrapper}>
      <PortableText className={styles.copy} blocks={fundraisingCopy} />
      <CtaButton
        label={fundraisingCta.label}
        url={fundraisingCta.url}
        theme="orange"
      />
    </div>
  );
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.copyWrapperDesktop}>
            <h3 className={styles.title}>{fundraisingTitle}</h3>
            <div className={utilStyles.desktopOnly}>{TextWithCta}</div>
          </div>
          {fundraisingImage && (
            <Image
              className={styles.image}
              image={fundraisingImage}
              hasRoundedCorners
            />
          )}
          <div className={utilStyles.mobileOnly}>{TextWithCta}</div>
        </div>
      </Container>
    </div>
  );
};

export default FundraisingSection;
