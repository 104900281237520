import { FC, useEffect, useState } from "react";
import { getTiltifyData } from "../lib/tiltify";

import * as styles from "./CampaignTracker.module.scss";

export type TiltifyConfig = {
  tiltifyEventId: string;
  tiltifyClientId: string;
  tiltifyClientSecret: string;
  tiltifyAmountRaisedOverride?: number;
  tiltifyExtraAmountRaised?: number;
  tiltifyTotalDonationTargetOverride?: number;
};

export interface CampaignTrackerProps {
  tiltifyConfig: TiltifyConfig;
}

const CampaignTracker: FC<CampaignTrackerProps> = ({ tiltifyConfig }) => {
  const {
    tiltifyEventId,
    tiltifyClientId,
    tiltifyClientSecret,
    tiltifyAmountRaisedOverride,
    tiltifyExtraAmountRaised,
    tiltifyTotalDonationTargetOverride,
  } = tiltifyConfig;
  const [campaignData, setCampaignData] = useState<any>();
  useEffect(() => {
    getTiltifyData(
      {
        tiltifyEventId,
        tiltifyClientId,
        tiltifyClientSecret,
      },
      setCampaignData
    );
  }, []);

  let totalAmountRaised: number;
  let fundraiserGoalAmount: number;

  totalAmountRaised = tiltifyAmountRaisedOverride
    ? tiltifyAmountRaisedOverride
    : Number(campaignData?.data?.total_amount_raised.value);
  totalAmountRaised += tiltifyExtraAmountRaised || 0;

  fundraiserGoalAmount = tiltifyTotalDonationTargetOverride
    ? tiltifyTotalDonationTargetOverride
    : Number(campaignData?.data?.goal.value);

  const barWidth = (totalAmountRaised / fundraiserGoalAmount) * 100 || 0;

  const raisedStr =
    totalAmountRaised || totalAmountRaised === 0
      ? totalAmountRaised.toLocaleString()
      : "-";
  const goalStr =
    fundraiserGoalAmount || fundraiserGoalAmount === 0
      ? fundraiserGoalAmount.toLocaleString()
      : "-";

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.bar}>
          <div
            className={styles.barInner}
            style={{ width: `${barWidth}%` }}
          ></div>
        </div>
        <div className={styles.info}>
          <div>Raised: ${raisedStr}</div>
          <div>Goal: ${goalStr}</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTracker;
