import React from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site, homePage, posts }) => {
      const mappedPosts = posts
        ? mapEdgesToNodes(posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : [];

      return children({ site, homePage, posts: mappedPosts });
    }}
  />
);
const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      tiltifyEventId
      tiltifyClientId
      tiltifyClientSecret
      tiltifyAmountRaisedOverride
      tiltifyExtraAmountRaised
      tiltifyTotalDonationTargetOverride
      donationUrl
      ticketsUrl
      streamUrl
      streamathonLabel
      streamathonDate
      lanLabel
      lanDate
    }
    homePage: sanityHomePage {
      heroVideoDesktop {
        asset {
          url
        }
      }
      heroVideoMobile {
        asset {
          url
        }
      }
      donateCtaLabel
      ticketsCtaLabel
      streamCtaLabel
      aboutTitle
      _rawAboutCopy
      joinCtaLabel
      datesHidden
      sponsorsTitle
      sponsorsHidden
      desktopLogoGrid {
        asset {
          _id
        }
      }
      mobileLogoGrid {
        asset {
          _id
        }
      }
      fundraisingTitle
      _rawFundraisingCopy
      fundraisingCta {
        label
        url
      }
      fundraisingImage {
        asset {
          _id
        }
      }
      blogTitle
      blogArchiveCtaLabel
      gfgLogoHidden
      gfgLogo {
        asset {
          _id
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
