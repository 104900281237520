//Gets access token from auth endpoint
const getTiltifyAccessToken = async (tiltifyConfig) => {
  // https://cors-anywhere.herokuapp.com/corsdemo
  const PROXY =
    window?.location?.hostname === "localhost"
      ? "https://cors-anywhere.herokuapp.com/https://v5api.tiltify.com/"
      : "/cors-proxy/";

  const url = "oauth/token";
  const data = {
    client_id: tiltifyConfig.tiltifyClientId,
    client_secret: tiltifyConfig.tiltifyClientSecret,
    grant_type: "client_credentials",
    scope: "cause",
  };
  const headers = {
    "Content-Type": "application/json",
  };

  const authResponse = await fetch(`${PROXY}${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  }).then((authResponse) => authResponse.json());

  return authResponse;
};

export const getTiltifyData = async (tiltifyConfig, callBackFunc) => {
  const PROXY =
    window?.location?.hostname === "localhost"
      ? "https://cors-anywhere.herokuapp.com/https://v5api.tiltify.com/"
      : "/cors-proxy/";
  const url = `api/cause/fundraising_events/${tiltifyConfig.tiltifyEventId}`;

  getTiltifyAccessToken(tiltifyConfig).then((authResponse) => {
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${authResponse.access_token}`,
    };
    // after the access token is recieved, make request for event data
    fetch(`${PROXY}${url}`, {
      headers,
    })
      .then((response) => response.json())
      .then((json) => callBackFunc(json));
  });
};
