import { FC } from "react";
import * as styles from "./BlogHighlights.module.scss";
import BlogItemCard from "./BlogItemCard";
import Container from "./Container";
import CtaButton from "./CtaButton";
import { SanityHomePage, SanityPost } from "../../graphql-types";
import { cn } from "../lib/helpers";

type BlogHighlightsProps = Pick<
  SanityHomePage,
  "blogTitle" | "blogArchiveCtaLabel"
> & {
  posts: SanityPost[];
};

const BlogHighlights: FC<BlogHighlightsProps> = ({
  blogTitle,
  posts,
  blogArchiveCtaLabel,
}) => {
  return (
    <div className={styles.root}>
      <Container>
        {blogTitle && <h2 className={cn(styles.title)}>{blogTitle}</h2>}
        <div className={styles.items}>
          {posts &&
            posts.slice(0, 4).map((item) => (
              <div key={item.id} className={styles.cardWrapper}>
                <BlogItemCard {...item} theme="orange" />
              </div>
            ))}
        </div>
        <CtaButton to="/blog" label={blogArchiveCtaLabel} />
      </Container>
    </div>
  );
};

export default BlogHighlights;
