import * as styles from "./BlogItemCard.module.scss";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { Link } from "gatsby";
import { format } from "date-fns";
import { imageUrlFor } from "../lib/image-url";
import { FC } from "react";

import { SanityPost } from "../../graphql-types";

interface BlogItemCardProps extends SanityPost {
  theme?: "gold" | "orange";
}

const BlogItemCard: FC<BlogItemCardProps> = ({
  publishedAt,
  slug,
  mainImage,
  title,
  theme = "gold",
}) => {
  return (
    <div className={styles.root}>
      <Link to={getBlogUrl(publishedAt, slug.current)}>
        <div className={styles.leadMediaThumb}>
          {mainImage && mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(768)
                .height(Math.floor((9 / 16) * 600))
                .auto("format")
                .url()}
              alt={mainImage.alt}
            />
          )}
        </div>
        <div className={styles.content}>
          <h3 className={cn(styles.title, styles[theme])}>{title}</h3>
          <div className={styles.date}>
            {format(new Date(publishedAt), "MMMM do, yyyy")}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogItemCard;
